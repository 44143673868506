/* eslint-disable no-underscore-dangle */
import React from 'react';
import { navigate } from 'gatsby';

import withLightbox from '../Lightbox/settings';
import { destroy } from '../Cart/storage';

import { BorderRadius } from '../../utils/variables';

import loadComponents from '../Loadable';

const LightboxWrapper = loadComponents('lightbox-wrapper');
const Button = loadComponents('button-input');
const Title = loadComponents('h6');

class DeleteAccount extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      currentPassword: null,
    };
    this.deleteAccount = this.deleteAccount.bind(this);
    this.completeDelete = this.completeDelete.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onChange = event => {
    if (this._isMounted)
      this.setState({ [event.target.name]: event.target.value });
  };

  deleteAccount() {
    const { firebase, authUser } = this.props;
    const {
      doSignInWithGoogle,
      doSignInWithFacebook,
      googleAuthProvider,
      facebookAuthProvider,
      emailAuthProvider,
    } = firebase;
    const { currentPassword } = this.state;
    if (this._isMounted) {
      this.setState({ processing: true });
    }
    let credential = false;
    switch (authUser.providerData[0].providerId) {
      case 'google.com':
        doSignInWithGoogle().then(socialAuthUser => {
          credential = googleAuthProvider.credential(
            socialAuthUser._tokenResponse.oauthIdToken,
            socialAuthUser._tokenResponse.oauthAccessToken,
          );
          this.completeDelete(credential);
        });
        break;
      case 'facebook.com':
        doSignInWithFacebook().then(socialAuthUser => {
          credential = facebookAuthProvider.credential(
            socialAuthUser._tokenResponse.oauthIdToken,
            socialAuthUser._tokenResponse.oauthAccessToken,
          );
          this.completeDelete(credential);
        });
        break;
      default:
        credential = emailAuthProvider.credential(
          authUser.email,
          currentPassword,
        );
        this.completeDelete(credential);
        break;
    }
  }

  completeDelete(credential) {
    const { firebase, authUser, closeLightbox } = this.props;
    const {
      reAuthenticateUser,
      auth,
      deleteAccount,
      doSignOut,
      user,
      deleteDatabase,
    } = firebase;
    reAuthenticateUser(auth.currentUser, credential)
      .then(() => {
        deleteDatabase(user(authUser.uid));
        deleteAccount(auth.currentUser).then(() => {
          closeLightbox();
          doSignOut().then(() => {
            destroy();
            navigate('/account/login/');
          });
        });
      })
      .catch(err => alert(err.message));
  }

  render() {
    const { processing } = this.state;
    const { showLightbox, openLightbox, closeLightbox, authUser } = this.props;
    return (
      <>
        <p>
          If you would like to delete your account and all details associated
          with your account you can use the button below. Please note that this
          cannot be reversed.
        </p>
        <Button
          value="Delete My Account"
          style={{ maxWidth: '300px' }}
          onClick={e => openLightbox(e)}
          secondary
          dark
        />
        <LightboxWrapper
          showLightbox={showLightbox}
          closeLightbox={closeLightbox}
          noLoader
        >
          <div style={modalInner}>
            {authUser.providerData[0].providerId === 'password' ? (
              <>
                <Title size="24px" margin="1.875rem" line={1.6}>
                  Please enter your password to confirm account deletion
                </Title>
                <input
                  name="currentPassword"
                  id="currentPassword"
                  onChange={this.onChange}
                  type="password"
                  placeholder="Current Password"
                  title="Current Password"
                  aria-label="Current Password"
                  style={{
                    marginBottom: `0.9375rem`,
                  }}
                />
              </>
            ) : (
              <Title size="24px" margin="1.875rem" line={1.6}>
                Click the button below to delete your account
              </Title>
            )}
            <Button
              value="Yes Delete My Account"
              className={processing ? 'processing' : ''}
              primary
              style={{ maxWidth: '100%', backgroundPosition: '90% 50%' }}
              onClick={this.deleteAccount}
            />
          </div>
        </LightboxWrapper>
      </>
    );
  }
}

export default withLightbox(DeleteAccount);

const modalInner = {
  padding: `2.8125rem`,
  backgroundColor: `#fff`,
  borderRadius: BorderRadius,
  width: `420px`,
  maxWidth: `100%`,
  margin: `0 auto`,
  textAlign: `center`,
};
